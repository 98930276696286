import React, { useContext, useEffect, useRef, useState } from "react";
import Footer from "../../Components/Footer/Footer";
// import Nav from "../../Components/Nav/Nav";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Nav from "../../Components/Navnew/Nav";
import Klik from "../../Asset/click.png";
import Payme from "../../Asset/payme.png";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

const OneTour = () => {
  let { id } = useParams();
  let location = useLocation()
  // console.log(location.search.includes('price'), 'location')
  const urlParams = new URLSearchParams(location.search);

// Get the value of the 'price' parameter
const price = urlParams.get("price");

  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [paymeShow, setPaymeShow] = useState(false);
  const [clickShow, setClickShow] = useState(false);
  const [bookShow, setBookShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [notSuccess, setNotSuccess] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const lang = window.localStorage.getItem("lang")
    ? window.localStorage.getItem("lang")
    : "en";
  // const [lang, setLanguage] = useState('en')
  // useEffect(()=>{
  //   setLanguage(localStorage.getItem("lang"))
  // },[localStorage])
  let dollar = 11.7;
  const [isloading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(`https://tur.web-sayt.uz/api/tours/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setData(...data.data.tour);
        console.log(...data.data.tour);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(true);
      });
  }, []);

  const formBtn = (e) => {
    setTimeout(() => {
      setSuccess(true);
      setBookShow(false);
    }, 1000);
    e.preventDefault();
    if (e.target[0].value.length > 0 && e.target[1].value.length > 0) {
      let botMessege = `
      Здравствуйте, администратор UZTURAGENCY, новое сообщение! 😊%0A
      Название тура  : ${data.title_eng}%0A
      Имя    👤: ${e.target[0].value}%0A
      Фамилия   👤: ${e.target[1].value}%0A
      Телефон номер   📧: ${e.target[1].value}%0A
      `;

      let url = `https://api.telegram.org/bot7859889008:AAHIkSdCOwbYoxmTwIble9J2FXlAKES8vRk/sendMessage?chat_id=-4535883531&text=${botMessege}`;
      async function fetchAsync(url) {
        let response = await fetch(url);
        let data = await response.json();
        return data;
      }
      fetchAsync(url);
      e.target[0].value = "";
      e.target[1].value = "";
      e.target[2].value = "";
    } else {
      setNotSuccess(true);
    }
  };
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  
  const Pay = async()=>{
    let form_data = {
      "octo_shop_id": 20216,
      "octo_secret": "857e6cfa-9639-4a35-bd0b-b41a71d3c897",
      "shop_transaction_id": uuidv4(),
      "auto_capture": true,
      // "test": true,
      "init_time": formatDate(new Date()),
      // "total_sum": 0.5,
      "total_sum": price ? price : data.price,
      "currency": "USD",
      "description": data.title_ru,
      "return_url": "https://www.uzturagency.uz/",
      // "notify_url": "https://webhook.site/7e378456-96f4-4aa2-b531-a9bd2cf66bc3", 
      "language": "ru"
    }
    await axios.post("https://secure.octo.uz/prepare_payment", form_data).then((res)=>{
      console.log(res, 'res')
      window.open(res.data.octo_pay_url)
    }).catch((err)=>{
      console.log(err)
    })
  }
  return (
    <>
      <SweetAlert
        danger
        confirmBtnText="ok"
        confirmBtnBsStyle="danger"
        title={t("not")}
        show={notSuccess}
        onConfirm={() => window.location.reload()}
        focusCancelBtn
      />
      <SweetAlert
        success
        title={t("msent")}
        show={success}
        onConfirm={() => window.location.reload()}
      />
      <SweetAlert
        title={t("info")}
        show={bookShow}
        onCancel={() => setBookShow(false)}
        showConfirm={false}
      >
        <div>
          <form onSubmit={formBtn}>
            <input className="form-control" placeholder={t("name")} required />
            <input
              className="form-control"
              style={{ margin: "10px 0px" }}
              placeholder={t("surname")}
              required
            />
            <input
              className="form-control"
              style={{ margin: "10px 0px" }}
              placeholder={t("number")}
              required
            />
            <button
              className="money glow-on-hover"
              style={{ color: "black", backgrounColor: "white" }}
              onClick={() => {}}
            >
              {t("send")}
            </button>
          </form>
        </div>
      </SweetAlert>
      <SweetAlert
        title={"Payment wih PAYME"}
        show={paymeShow}
        onCancel={() => setPaymeShow(false)}
        showConfirm={false}
      >
        <div>
          <input
            className="form-control"
            placeholder={"Full name"}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <input
            className="form-control"
            style={{ margin: "10px 0px" }}
            placeholder={"Phone Number"}
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
          <form method="POST" action="https://checkout.paycom.uz">
            {" "}
            <input
              type="hidden"
              name="merchant"
              defaultValue="64b27ea2d2cbaa16838ebe1e"
            />{" "}
            <input
              type="hidden"
              name="amount"
              defaultValue={Number(data.price * 11700)+"00"}
            />{" "}
            <input type="hidden" name="account[nazvaniya]" defaultValue={name} />{" "}
            <input
              type="hidden"
              name="account[Nomer]"
              defaultValue={phoneNumber}
            />{" "}
            <input type="hidden" name="account[sum]" defaultValue={Number(data.price * 11700)+"00"} />{" "}
            <button type="submit" className="money glow-on-hover">
              {" "}
              <img src={Payme} />{" "}
            </button>{" "}
          </form>
             {/* <form method="POST" action="https://checkout.paycom.uz"> <input type="hidden" name="merchant" value="64b27ea2d2cbaa16838ebe1e"/> <input type="hidden" name="amount" value="100000"/> <input type="hidden" name="account[nazvaniya]" value="aZIMJON"/> <input type="hidden" name="account[Nomer]" value="998909541262"/> <button type="submit" style="cursor: pointer;
border: 1px solid #ebebeb;
border-radius: 6px;
background: linear-gradient(to top, #f1f2f2, white);
width: 200px;
height: 42px;
display: flex;
align-items: center;
justify-content: center;"> <img style="width: 160px;
height: 20px;" src="http://cdn.payme.uz/buttons/buttonbig_EN.svg"/> </button> </form> */}
        </div>
      </SweetAlert>
      <SweetAlert
        title={"Payment wih Click"}
        show={clickShow}
        onCancel={() => setClickShow(false)}
        showConfirm={false}
      >
        <div>
          <input
            className="form-control"
            placeholder={"Full name"}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <input
            className="form-control"
            style={{ margin: "10px 0px" }}
            placeholder={"Phone Number"}
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
          <form
            id="click_form"
            className="text-center  w-80 text-white rounded-md cursor-pointer  text-3xl font-bold"
            action="https://my.click.uz/services/pay"
            method="get"
            target="_blank"
          >
            <input
              type="hidden"
              name="amount"
              value={data.price * dollar * 1000}
            />
            <input type="hidden" name="merchant_id" value="20898" />
            <input type="hidden" name="merchant_user_id" value="33539" />
            <input type="hidden" name="service_id" value="28519" />
            <input type="hidden" name="transaction_param" value={name} />
            <input
              type="hidden"
              name="return_url"
              value="https://www.UZTURAGENCY.uz/"
            />
            <input type="hidden" name="card_type" value="uzcard" />
            <button type="submit" className="money glow-on-hover">
              {" "}
              <img src={Klik} />{" "}
            </button>{" "}
          </form>
        </div>
      </SweetAlert>
      {isloading ? (
        <div className="load-wrap">
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          <Nav />
          <head>
            <link
              href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
              rel="stylesheet"
              integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
              crossorigin="anonymous"
            />
          </head>

          {/* endnav */}
          <div className="container hihello" style={{ marginBottom: "6rem" }}>
            <div className="leftg">
              <img
                src={`${data.img}`}
                alt="Hawaii"
                style={{ borderRadius: "10px" }}
              />
            </div>
            <div className="rightg">
              <h2>
                {lang == "en" && data.title_eng}
                {lang == "ru" && data.title_ru}
                {lang == "uz" && data.title_uz}
              </h2>
              <p></p>
              <p>
                {data.location}
                <br />
                {price ? price : data.price} $
                <br />
                <div dangerouslySetInnerHTML={{__html: lang == "en" ? data.description_eng : data.description_ru}}>
                </div>
              </p>
              <div className="money-wrap">
                {/* <button
                  className="money glow-on-hover"
                  onClick={() => {
                    setClickShow(true);
                  }}
                >
                  <img src={Klik} alt="" />
                </button> */}
                {/* <button
                  className="money glow-on-hover"
                  onClick={() => {
                    setPaymeShow(true);
                  }}
                >
                  <img src={Payme} alt="" />
                </button> */}
                <button
                  className="money glow-on-hover"
                  style={{ color: "black", backgrounColor: "white" }}
                  onClick={() => {
                    setBookShow(true);
                  }}
                >
                  {t("book")}
                </button>

                <button
                  className="money glow-on-hover"
                  style={{ color: "black", backgrounColor: "white" }}
                  onClick={() => {
                    Pay();
                  }}
                >
                  {t("pay")}
                </button>

                {/* <button className="money glow-on-hover"> */}
                {/* <form method="POST" action="https://checkout.paycom.uz">
                  {" "}
                  <input
                    type="hidden"
                    name="merchant"
                    defaultValue="63aae1344f030ccf8231ff03"
                  />{" "}
                  <input
                    type="hidden"
                    name="amount"
                    defaultValue={data.price*dollar*100000}
                  />{" "}
                  <input
                    type="hidden"
                    name="account[ISM]"
                    defaultValue="Azimjon Bekmuratov"
                  />{" "}
                  <input
                    type="hidden"
                    name="account[Tel]"
                    defaultValue={"+998909541262"}
                  />{" "}
                  <input
                    type="hidden"
                    name="account[sum]"
                    defaultValue={10000}
                  />{" "}
                  <button
                    type="submit"
                    className="money glow-on-hover"
                  >
                    {" "}
                    <img
                      src={Payme}
                    />{" "}
                  </button>{" "}
                </form> */}
                {/* <img src={Payme} alt="" />
                </button> */}
              </div>
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
};

export default OneTour;
